import request from '@/utils/request'

const temporaryApi = {
  temporary_car_list: '/temporary/car/'
}

/**
 * 获取授权车辆列表
 */
export function temporary_car_list (parameter) {
  return request({
    url: temporaryApi.temporary_car_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
